<template>
    <div
      class="
        d-flex
        flex-column flex-column-fluid
        bgi-position-y-bottom
        position-x-center
        bgi-size-contain bgi-attachment-fixed
      "
      style="background-image: url('media/background/wp.png')"
    >
      <!--begin::Content-->
      <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <!--begin::Logo-->
        <a href="https://ogzatech.com/tr/" class="mb-12">
          <img alt="Logo" src="/media/logos/logo.png" class="h-50px" />
        </a>
        <!--end::Logo-->
  
        <router-view></router-view>
      </div>
      <!--end::Content-->
  
      <!--begin::Footer-->
      <div class="d-flex flex-center flex-column-auto p-10">
        <!--begin::Links-->
        <div class="d-flex align-items-center fw-bold fs-6">
          <a
            href="https://ogzatech.com/tr/"
            class="text-muted text-hover-primary px-2"
            >{{ $t("common.layout.contact") }}</a
          >
        </div>
        <!--end::Links-->
      </div>
      <!--end::Footer-->
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { Actions } from "@/presentation/store/enums/StoreEnums";
  import { useI18n } from "vue-i18n";
  
  export default defineComponent({
    name: "auth",
    components: {},
    setup() {
      const { t } = useI18n();
      const store = useStore();
  
      onMounted(() => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
      });
  
      onUnmounted(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
      });
    },
  });
  </script>
  